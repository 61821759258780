import React from "react"
import MainLayout from "../../layouts"
import { Col, Container, Row } from "react-bootstrap"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import ContactHeading from "../../components/ContactHeading"
import ServiceCards from "../../ServiceCards"

export const pageQuery = graphql`
  query {
    antelopeImage: file(relativePath: { eq: "antelope.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    locksmithWithCarImage: file(
      relativePath: { eq: "locksmith-with-car.jpg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 337) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

function AntelopePage({ data }) {
  return (
    <MainLayout
      title="24 Hour Full Service Locksmith in Antelope (916) 995-0135"
      description="Professional, dependable, auto, home, commercial & emergency locksmith services. Licensed, Insured & Bonded! Lockouts, rekeys, repair locks & more."
      offset
    >
      <ContactHeading background={data.antelopeImage.childImageSharp.fluid}>
        <h1 className="text-center">Antelope Full Service Locksmith</h1>
        <h2 className="text-center font-weight-light mb-0">
          24 Hour Antelope Locksmith
        </h2>
      </ContactHeading>
      <section>
        <ServiceCards />
      </section>
      <Container as="section">
        <h2 className="text-center">Antelope's Leading Locksmith</h2>
        <p className="indent">
          Whether you’re locked out in Antelope, CA, need a lock changed, or
          keys replaced, Swift Locksmith help is just a call away. Our Antelope
          Locksmiths understand how stressful it can be to be in these
          situations and we are devoted to serving the Antelope area. Every day
          we help busy people like you to take care of these issues and get back
          to living your life. We are available 24/7, seven days a week and work
          on holidays so you can always rely on Swift Locksmith to get the job
          done. Our technicians are trained to handle any lock situation and we
          are affordable, fast, and friendly.
        </p>
      </Container>
      <section>
        <div className="background-content bg-dark text-white pb-0 pb-md-6">
          <Container>
            <h2 className="text-center text-primary">
              We Provide Top Quality Locksmith & Security Services
            </h2>
            <Row>
              <Col xs={12} md={6}>
                <p>
                  We offer local locksmith services for auto, home and
                  commercial. Here at Swift Locksmith in Antelope, CA, our staff
                  takes great pride in providing outstanding security services
                  to all of clients. Our technicians are always friendly,
                  knowledgeable and respond quickly to our customers' emergency
                  and non-emergency locksmith needs.
                </p>
                <p className="mb-md-0">
                  We are fully mobile and cover all of the Antelope, CA area.
                  Our vans are fully equipped with the proper tools and
                  equipment to get a job done quickly and efficiently at your
                  location and at your convenience. We want to ensure that all
                  of our customers are getting the best customer service with
                  competitive pricing with no hidden fees. Call us today and see
                  how we can help you!
                </p>
              </Col>
              <Col xs={12} md={6} className="col-sm-pad">
                <Img
                  fluid={data.locksmithWithCarImage.childImageSharp.fluid}
                  alt="locksmith-with-car"
                />
              </Col>
            </Row>
          </Container>
        </div>
      </section>
      <Container as="section" className="mb-5">
        <h2 className="text-center">
          Why Swift Locksmith Stands Out Of The Competition
        </h2>
        <p>
          What sets Antelope Swift Locksmith apart from the competition is our
          competitive pricing, professionalism, and passion for the business. We
          are always happy to help with all of your locksmith needs whether you
          need a hand getting back into your home or car or you want to ensure
          that your home security is at its highest level.
        </p>
        <p>
          We pride ourselves on a commitment to doing business the right way and
          offer emergency locksmith services in the Antelope, CA area. Trusting
          us to help you enter your home or double check your current security
          systems is a privilege we don’t take lightly. All of Swift Locksmith
          technicians are highly trained and fully licensed and are held to an
          incredibly high standard of professionalism.
        </p>
        <p className="mb-0">
          Swift Locksmith has been serving Antelope, CA for over 10 years and we
          can solve all of your residential, commercial or automotive needs. We
          cover locksmith services such as lockouts, repair and replace locks,
          new car keys, re-key locks and even safes. We are here to provide you
          with high quality and fast locksmith services in Antelope, CA so
          whatever your locksmith need is, give us a call today and our experts
          will help you!
        </p>
      </Container>
    </MainLayout>
  )
}

export default AntelopePage
